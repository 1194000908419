import { FormInputProps } from "./props/FormInputProps";
import { TextField } from "@mui/material";
import { Colors } from "../../../../theme/colors";
import { Controller } from "react-hook-form";
import { InputWrapper } from "./InputWrapper";

export const TextInput = <TFormValues,>({
  name,
  title,
  disabled = false,
  form,
  required = false,
  placeholder,
  inputProps,
  multiline,
  helperText,
  fieldError,
  hideTitle = false,
}: FormInputProps<TFormValues>) => {
  return (
    <InputWrapper title={hideTitle ? undefined : title} required={required}>
      <Controller
        name={name}
        control={form.control}
        render={({ field }) => (
          <TextField
            {...field}
            sx={{
              background: Colors.white,
              ".MuiInputBase-root fieldset": {
                borderColor: `${Colors.grey200} !important`,
              },
            }}
            id={name}
            type={"text"}
            size="small"
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            InputProps={inputProps}
            multiline={multiline}
            helperText={fieldError?.message ?? helperText}
            error={!!fieldError}
            {...form?.register(name, {
              required: required && `Pole ${title?.toLowerCase()} nesmí být prázdné`,
            })}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      />
    </InputWrapper>
  );
};
