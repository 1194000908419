import { QuestionDtoOut } from "../../generated";
import { Question } from "../../model/questions/Question";
import { parseAnswer } from "./parseAnswer";
import { parseQuestionType } from "./parseQuestionType";

export const parseQuestion = (data: QuestionDtoOut): Question => {
  return {
    id: data.id,
    text: data.text,
    type: parseQuestionType(data.type),
    answers: data.answers.map(parseAnswer),
  };
};
