import { Box, Stack, SxProps } from "@mui/material";
import {
  dataGridAnimateOnHover,
  dataGridHideHeader,
  dataGridStyles,
  outerBoxStyles,
} from "../../../theme/overrides/MuiDatagridOverrides";
import {
  DataGrid,
  GridCallbackDetails,
  GridColumns,
  GridFeatureModeConstant,
  GridRowId,
  GridRowIdGetter,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  MuiEvent,
} from "@mui/x-data-grid";
import { PaginationProps } from "../../../model/PaginationProps";

interface Props<R extends GridValidRowModel> extends Partial<PaginationProps> {
  rows?: GridRowsProp<R>;
  columns: GridColumns<R>;
  onRowClick?: (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => void;
  animateOnHover?: boolean;
  selectionModel?: GridRowId[];
  onSelectionModelChange?: (ids: string[]) => void;
  hideHeader?: boolean;
  getRowId?: GridRowIdGetter<R>;
  noRowsMessage?: string;
  showCheckbox?: boolean;
}

export const AbstractDataGrid = <R,>({
  rows,
  columns,
  onRowClick,
  animateOnHover = false,
  hideHeader = false,
  showCheckbox = false,
  ...props
}: Props<R>) => {
  rows = rows ?? [];
  let gridStyle = dataGridStyles;
  if (animateOnHover) {
    gridStyle = { ...gridStyle, ...dataGridAnimateOnHover } as SxProps;
  }
  if (hideHeader) {
    gridStyle = { ...gridStyle, ...dataGridHideHeader } as SxProps;
  }

  if (!rows.length) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"} height={"50px"} bgcolor={"white"} borderRadius={1}>
        {props.noRowsMessage ?? "Žádná data"}
      </Stack>
    );
  }

  return (
    <Box sx={outerBoxStyles}>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={gridStyle}
        autoHeight
        disableSelectionOnClick={!animateOnHover}
        disableColumnMenu
        onRowClick={onRowClick}
        selectionModel={props.selectionModel}
        getRowSpacing={() => ({
          top: 5,
          bottom: 5,
        })}
        onSelectionModelChange={(rowIds) => {
          if (props.onSelectionModelChange) {
            const parsedRowIds = rowIds.map((id) => String(id));
            props.onSelectionModelChange(parsedRowIds);
          }
        }}
        getRowId={props.getRowId}
        hideFooter={props.page === undefined}
        pagination={props.page !== undefined ? true : undefined}
        paginationMode={GridFeatureModeConstant.server}
        rowCount={props.totalElements}
        page={props.page}
        pageSize={props.pageSize}
        onPageChange={props.handlePageChange}
        onPageSizeChange={props.handlePageSizeChange}
        rowsPerPageOptions={[10, 20, 50, 100]}
        columnThreshold={3}
        checkboxSelection={showCheckbox}
      />
    </Box>
  );
};
