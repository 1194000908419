import { Header } from "../../layout/Header";
import { Alert, Box, Button, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useState } from "react";
import { useAsyncCall } from "react-hook-async-call";
import { getAllPatients } from "../../../services/patient/getAllPatients";
import { useOnMount } from "../../../hooks/useOnMount";
import { Loading } from "../../ui/loading/Loading";
import { useAlerts } from "../../../hooks/useAlerts";
import { PatientListColumns } from "./PatientListColumns";
import { AbstractDataGrid } from "../../ui/datagrid/AbstractDataGrid";
import { SelectCareDialog } from "../patient-detail/dialogs/SelectCareDialog";
import { postEndCare } from "../../../services/patient-care/postEndCare";
import { Check, Close, Search } from "@mui/icons-material";
import { Colors } from "../../../theme/colors";
import { FontWeight } from "../../../theme/utils";

const defaultPageSize = 10;

export const PatientsPage = () => {
  const alerts = useAlerts();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectCareOpened, setSelectCareOpened] = useState<boolean>(false);

  const [personalNumber, setPersonalNumber] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");

  const [getPatientList, patientList, loading] = useAsyncCall(
    (page, pageSize, personalNumber?: string, fullName?: string) =>
      getAllPatients(page, pageSize, personalNumber, fullName),
    undefined,
    undefined,
    (e) => alerts.error(e)
  );
  const [endCare] = useAsyncCall(
    postEndCare,
    undefined,
    () => {
      alerts.success(`Péče byla úspěšně ukončena.`);
    },
    (e) => alerts.error(e)
  );

  const patients = patientList ? patientList.patients : [];

  useOnMount(() => {
    getPatientList(0, defaultPageSize);
  });

  const handlePageChange = (page: number) => {
    getPatientList(page, patientList?.pageSize ?? defaultPageSize);
  };

  const handlePageSizeChange = (pageSize: number) => {
    getPatientList(0, pageSize);
  };

  const handleEndCareClick = async () => {
    if (confirm("Ukončit péči vybraným pacientům?")) {
      await Promise.all(selectedIds.map(endCare));
      getPatientList(0, defaultPageSize);
      setSelectedIds([]);
    }
  };

  const handleSearch = () => {
    getPatientList(0, defaultPageSize, personalNumber.replace(/\D/g, ""), fullName);
  };

  return (
    <>
      <Header title={"Moji pacienti"}>
        <Stack
          direction={"row"}
          gap={0.5}
          px={2}
          py={1}
          alignItems={"center"}
          sx={{ background: Colors.white, borderRadius: "6px", border: `1px solid ${Colors.grey200}` }}
        >
          <Box mr={2} fontWeight={FontWeight.Bold}>
            Vyhledávání
          </Box>
          <TextField
            label={"Rodné číslo"}
            value={personalNumber}
            type={"text"}
            size={"small"}
            disabled={loading}
            onChange={(e) => setPersonalNumber(e.currentTarget.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"} onClick={() => setPersonalNumber("")}>
                  <IconButton size={"small"} sx={{ visibility: personalNumber !== "" ? "visible" : "hidden" }}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label={"Jméno nebo příjmení"}
            value={fullName}
            type={"text"}
            size={"small"}
            disabled={loading}
            onChange={(e) => setFullName(e.currentTarget.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"} onClick={() => setFullName("")}>
                  <IconButton size={"small"} sx={{ visibility: fullName !== "" ? "visible" : "hidden" }}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
          <IconButton onClick={handleSearch} color={"primary"} disabled={loading} sx={{ ml: 1 }}>
            <Search />
          </IconButton>
        </Stack>
      </Header>

      {(() => {
        if (loading) {
          return <Loading />;
        } else if (patientList) {
          return (
            <>
              {selectedIds.length > 0 && (
                <Alert sx={{ mb: 3 }}>
                  <Stack direction={"row"} flex={1} width={"100%"} alignItems={"center"}>
                    <Stack flex={1}>Vybráno {selectedIds.length} pacientů</Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <Button
                        onClick={() => {
                          setSelectCareOpened(true);
                        }}
                        endIcon={<Check />}
                      >
                        Nastavit péči
                      </Button>
                      <Button onClick={handleEndCareClick} variant={"outlined"} endIcon={<Close />}>
                        Ukončit péči
                      </Button>
                    </Stack>
                  </Stack>
                </Alert>
              )}
              <AbstractDataGrid
                rows={patients}
                columns={PatientListColumns}
                noRowsMessage={
                  personalNumber === "" ? "Nejsou k dispozici žádní pacienti" : "Hledanému výrazu nic neodpovídá"
                }
                page={patientList.page}
                pageSize={patientList.pageSize}
                totalElements={patientList.totalElements}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                showCheckbox
                onSelectionModelChange={setSelectedIds}
              />
              {selectedIds.length > 0 && (
                <SelectCareDialog
                  open={selectCareOpened}
                  onClose={setSelectCareOpened}
                  patientIds={selectedIds}
                  onSubmit={() => {
                    getPatientList(0, defaultPageSize);
                  }}
                />
              )}
            </>
          );
        }
      })()}
    </>
  );
};
