import { AbstractDialog } from "../../../../../ui/dialogs/AbstractDialog";
import { Button, Stack } from "@mui/material";
import { SelectInput } from "../../../../../ui/form/inputs/SelectInput";
import { useForm } from "react-hook-form";
import { useAsyncCall } from "react-hook-async-call";
import { getAllWardRoundTemplates } from "../../../../../../services/ward-round-template/getAllWardRoundTemplates";
import { useOnMount } from "../../../../../../hooks/useOnMount";
import AddIcon from "@mui/icons-material/Add";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { NumberInput } from "../../../../../ui/form/inputs/NumberInput";
import { SwitchInput } from "../../../../../ui/form/inputs/SwitchInput";
import { WardRoundEntry } from "../../../../../../model/ward-round/WardRoundEntry";
import { RepetitionGranularity } from "../../../../../../model/enum/RepetitionGranularity";
import { Colors } from "../../../../../../theme/colors";
import CheckIcon from "@mui/icons-material/Check";
import { FormHelperText } from "../../../../../ui/form/FormHelperText";
import { useEffect, useMemo, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import { DeleteWardRoundFromCareDialog } from "./DeleteWardRoundFromCareDialog";
import { CareTemplate } from "../../../../../../model/templates/CareTemplate";
import { NavigationRoutes } from "../../../../../../model/constants/NavigationRoutes";

interface Props {
  open: boolean;
  onClose: () => void;
  onSaveWardRound: (data: WardRoundEntry, index?: number) => void;
  onDeleteWardRound: (index: number) => void;
  data?: WardRoundEntry;
  index?: number;
  selectedCare: CareTemplate;
}

export const AddWardRoundToCareDialog = (props: Props) => {
  const isEditing = props.data;
  const [getAllWardRoundsCall, wardRoundTemplates] = useAsyncCall(getAllWardRoundTemplates, []);
  const [deleteWardRoundFromCareDialogOpen, setDeleteWardRoundFromCareDialogOpen] = useState<boolean>(false);

  const defaultValues = useMemo(
    () => ({
      offsetDays: 0,
      isRecurring: false,
      repetitionGranularity: RepetitionGranularity.Day,
      repetitionValue: 1,
      numberOfOccurrences: 1,
    }),
    []
  );

  const form = useForm<WardRoundEntry>({ mode: "onChange", defaultValues });
  const isRecurring = form.watch("isRecurring");

  const isValid = () => {
    if (!form.formState.isDirty) return false;
    if (!isRecurring) return form.watch("wardRoundTemplate") && !form.formState.errors.offsetDays;
    return !Object.keys(form.formState.errors).length && form.watch("wardRoundTemplate");
  };

  useOnMount(() => {
    getAllWardRoundsCall();
  });

  useEffect(() => {
    isEditing ? form.reset(props.data) : form.reset(defaultValues);
  }, [defaultValues, form, isEditing, props.data]);

  const options = wardRoundTemplates.map((wardRoundTemplate) => ({
    value: wardRoundTemplate,
    label: wardRoundTemplate.title,
    key: wardRoundTemplate.id,
  }));

  const granularityOptions = Object.keys(RepetitionGranularity).map((key) => {
    return {
      key,
      value: RepetitionGranularity[key as keyof typeof RepetitionGranularity],
      label: RepetitionGranularity[key as keyof typeof RepetitionGranularity],
    };
  });

  const repetitionText = () => {
    const sharedText = "Vizita se bude opakovat";
    const granularity = form.watch("repetitionGranularity");
    const value = form.watch("repetitionValue");

    if (typeof value !== "number") return;

    switch (granularity) {
      case RepetitionGranularity.Minute:
        if (value === 1) return `${sharedText} každou minutu`;
        if (value > 1 && value < 5) return `${sharedText} každé ${value} minuty`;
        return `${sharedText} každých ${value} minut`;
      case RepetitionGranularity.Hour:
        if (value === 1) return `${sharedText} každou hodinu`;
        if (value > 1 && value < 5) return `${sharedText} každé ${value} hodiny`;
        return `${sharedText} každých ${value} hodin`;
      case RepetitionGranularity.Day:
        if (value === 1) return `${sharedText} každý den`;
        if (value > 1 && value < 5) return `${sharedText} každé ${value} dny`;
        return `${sharedText} každých ${value} dnů`;
      case RepetitionGranularity.Week:
        if (value === 1) return `${sharedText} každý týden`;
        if (value > 1 && value < 5) return `${sharedText} každé ${value} týdny`;
        return `${sharedText} každých ${value} týdnů`;
      case RepetitionGranularity.Month:
        if (value === 1) return `${sharedText} každý měsíc`;
        if (value > 1 && value < 5) return `${sharedText} každé ${value} měsíce`;
        return `${sharedText} každých ${value} měsíců`;
    }
  };

  const handleAddWardRound = () => {
    const wardRoundEntry = form.getValues();
    props.onSaveWardRound(
      {
        ...wardRoundEntry,
        repetitionGranularity: wardRoundEntry.isRecurring ? wardRoundEntry.repetitionGranularity : undefined,
        repetitionValue: wardRoundEntry.isRecurring ? wardRoundEntry.repetitionValue : undefined,
        numberOfOccurrences: wardRoundEntry.isRecurring ? wardRoundEntry.numberOfOccurrences : undefined,
      },
      isEditing ? props.index : undefined
    );
    props.onClose();
  };

  const handleDeleteWardRound = () => {
    if (props.index !== undefined) {
      props.onDeleteWardRound(props.index);
    }
    props.onClose();
  };

  const actionButtons = (
    <Stack flex={1} direction={"row"} justifyContent={isEditing ? "space-between" : "flex-end"}>
      {isEditing && (
        <Button color={"error"} endIcon={<Delete />} onClick={() => setDeleteWardRoundFromCareDialogOpen(true)}>
          Odstranit z péče
        </Button>
      )}
      <Button
        color={"success"}
        endIcon={isEditing ? <Edit /> : <AddIcon />}
        disabled={!isValid()}
        onClick={handleAddWardRound}
      >
        {isEditing ? "Upravit" : "Přidat"}
      </Button>
      <DeleteWardRoundFromCareDialog
        open={deleteWardRoundFromCareDialogOpen}
        index={props.index}
        onClose={() => setDeleteWardRoundFromCareDialogOpen(false)}
        onConfirm={handleDeleteWardRound}
        selectedCare={props.selectedCare}
        selectedWardRound={props.data}
      />
    </Stack>
  );

  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      title={isEditing ? "Upravit vizitu" : "Přidat vizitu"}
      buttons={actionButtons}
      width={"md"}
      alertOnClose={form.formState.isDirty}
    >
      <Stack direction={"row"} gap={1} alignItems={"end"}>
        <Stack width={"100%"}>
          <SelectInput
            title={"Vizita"}
            required
            name={"wardRoundTemplate"}
            form={form}
            options={options}
            optionsValueComparator={(a, b) => a && b && a.id === b.id}
          />
        </Stack>
        <Button
          endIcon={<OpenInNewIcon />}
          variant={"text"}
          sx={{ marginBottom: 0.3 }}
          href={NavigationRoutes.wardRoundTemplateDetail.getLink(props.data?.wardRoundTemplate.id)}
          target={"_blank"}
        >
          Detail vizity
        </Button>
      </Stack>
      <NumberInput
        title={"Odložit první výskyt (dny)"}
        required
        name={"offsetDays"}
        form={form}
        helperText={"Počet dnů od propuštění pacienta či prvního výskytu předchozí vizity"}
        fieldError={form.formState.errors.offsetDays}
        min={0}
      />
      <SwitchInput
        bold
        title={"Opakovat vizitu"}
        enabled={isRecurring}
        onChange={(value) => {
          form.setValue("isRecurring", value);
        }}
      />
      {isRecurring && (
        <Stack>
          <Stack direction={"row"} width={"100%"} gap={1}>
            <Stack flex={1}>
              <NumberInput
                name={"repetitionValue"}
                required={isRecurring}
                form={form}
                title={"Interval"}
                fieldError={form.formState.errors.repetitionValue}
                min={form.watch("repetitionGranularity") === RepetitionGranularity.Minute ? 5 : 1}
              />
              <FormHelperText
                icon={<CheckIcon />}
                color={Colors.success}
                fieldError={form.formState.errors.repetitionValue}
              >
                {repetitionText()}
              </FormHelperText>
            </Stack>
            <Stack flex={1}>
              <SelectInput
                name={"repetitionGranularity"}
                required={isRecurring}
                form={form}
                title={"Měřítko"}
                options={granularityOptions}
                fieldError={form.formState.errors.repetitionGranularity}
              />
            </Stack>
          </Stack>
          <NumberInput
            name={"numberOfOccurrences"}
            form={form}
            title={"Počet výskytů"}
            required={isRecurring}
            fieldError={form.formState.errors.numberOfOccurrences}
            min={2}
          />
          <FormHelperText
            icon={<CheckIcon />}
            color={Colors.success}
            fieldError={form.formState.errors.numberOfOccurrences}
          >
            {typeof form.watch("numberOfOccurrences") === "number" &&
              `Vizita se provede celkem ${form.watch("numberOfOccurrences")}x`}
          </FormHelperText>
        </Stack>
      )}
    </AbstractDialog>
  );
};
