import { PatientDetailDtoOut, PatientDtoOut, Sex as SexGenerated } from "../../generated";
import { Patient } from "../../model/patient/Patient";
import { parseSeverityDetail } from "../parseSeverityDetail";
import { Sex as SexEnum } from "../../model/enum/Sex";
import { parsePatientCare } from "../care/parsePatientCare";

export const parsePatient = (data: PatientDtoOut, detail?: PatientDetailDtoOut): Patient => {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    personalNumber: data.personalNumber,
    phoneNumber: data.phoneNumber,
    age: data.age ? `${data.age} let` : "",
    email: data.email ?? "",
    weight: detail?.weight ?? 0,
    height: detail?.height ?? 0,
    sex: parseSex(data.sex),
    critical: detail?.critical ?? false,
    severityDetail: detail?.severityDetail && parseSeverityDetail(detail.severityDetail),
    care: detail?.care && parsePatientCare(detail?.care),
    endedCares: detail && detail.endedCares.map(parsePatientCare),
    lastActivityAt: data.lastActivityAt ? new Date(data.lastActivityAt) : undefined,
    isCritical: data.critical === null ? undefined : data.critical,
    careName: data.careName,
  };
};

const parseSex = (sex?: SexGenerated): SexEnum => {
  switch (sex) {
    case SexGenerated.Male:
      return SexEnum.Male;
    case SexGenerated.Female:
      return SexEnum.Female;
    default:
      return SexEnum.Undefined;
  }
};
