import {
  CareTemplateControllerApi,
  Configuration,
  LoginControllerApi,
  PatientCaresControllerApi,
  PatientChallengeControllerApi,
  PatientControllerApi,
  PatientWardRoundOccurrencesControllerApi,
  PatientWardRoundsControllerApi,
  QuestionnaireControllerApi,
  QuestionnaireTemplateControllerApi,
  QuestionTemplateControllerApi,
  WardRoundTemplateControllerApi,
} from "../generated";
import { getApiUrl } from "../helpers/getApiUrl";
import { getLocalUser } from "./auth/getLocalUser";

const getApiConfiguration = () => {
  const user = getLocalUser();

  return new Configuration({
    baseOptions: { withCredentials: true, headers: user ? { Authorization: `Bearer ${user.token}` } : "" },
  });
};

export const initApiServices = () => {
  const config = getApiConfiguration();
  const apiUrl = getApiUrl();

  return {
    auth: new LoginControllerApi(config, apiUrl),
    patient: new PatientControllerApi(config, apiUrl),
    patientWardRound: new PatientWardRoundsControllerApi(config, apiUrl),
    patientWardRoundOccurrence: new PatientWardRoundOccurrencesControllerApi(config, apiUrl),
    questionnaire: new QuestionnaireControllerApi(config, apiUrl),
    patientChallenge: new PatientChallengeControllerApi(config, apiUrl),
    questionnaireTemplates: new QuestionnaireTemplateControllerApi(config, apiUrl),
    careTemplates: new CareTemplateControllerApi(config, apiUrl),
    patientCare: new PatientCaresControllerApi(config, apiUrl),
    questionTemplate: new QuestionTemplateControllerApi(config, apiUrl),
    wardRoundTemplates: new WardRoundTemplateControllerApi(config, apiUrl),
  };
};

export const api = {
  services: initApiServices(),
};
