import { Avatar, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { Colors } from "../../theme/colors";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../model/constants/NavigationRoutes";
import { useUser } from "../../hooks/useUser";
import RuleIcon from "@mui/icons-material/Rule";
import { Border } from "../../theme/utils";
import { AssignmentOutlined, GroupOutlined } from "@mui/icons-material";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import { useState } from "react";
import { useOnMount } from "../../hooks/useOnMount";
import { SideBarTab } from "../../model/enum/SideBarTab";

const SideBarMenu = () => {
  const navigate = useNavigate();
  const user = useUser();
  const [activeTab, setActiveTab] = useState<SideBarTab>(SideBarTab.Patients);
  const pathname = useLocation().pathname.split("/")[1] as SideBarTab;

  useOnMount(() => {
    if (Object.values(SideBarTab).includes(pathname)) setActiveTab(pathname);
  });

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        height: "100vh",
        pb: 4,
        pt: 3,
        borderRight: Border.Grey.Thin,
      }}
    >
      <Stack gap={2} alignItems={"center"}>
        <Avatar sx={{ color: Colors.primary, bgcolor: Colors.primaryLightBg, width: 56, height: 56 }}>MB</Avatar>
        <Tabs
          orientation={"vertical"}
          value={activeTab}
          onChange={(_, tab) => setActiveTab(tab)}
          sx={{ ".MuiTab-root": { fontSize: 14 } }}
        >
          <Tab
            icon={<GroupOutlined />}
            label={"Pacienti"}
            value={SideBarTab.Patients}
            onClick={() => navigate(NavigationRoutes.patients.getLink())}
          />
          <Tab
            icon={<RuleIcon />}
            label={"Dotazníky"}
            value={SideBarTab.Questionnaires}
            onClick={() => navigate(NavigationRoutes.questionnaireTemplates.getLink())}
          />
          <Tab
            icon={<AssignmentOutlined />}
            label={"Vizity"}
            value={SideBarTab.WardRounds}
            onClick={() => navigate(NavigationRoutes.wardRoundTemplates.getLink())}
          />
          <Tab
            icon={<BackupTableIcon />}
            label={"Péče"}
            value={SideBarTab.Cares}
            onClick={() => navigate(NavigationRoutes.careTemplates.getLink())}
          />
        </Tabs>
      </Stack>
      <Stack>
        <IconButton onClick={user.logOut}>
          <LogoutIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default SideBarMenu;
