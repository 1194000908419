import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppWrapper } from "./AppWrapper";
import { SnackbarProvider } from "notistack";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const App = () => {
  return (
    <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path={"*"} element={<AppWrapper />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
