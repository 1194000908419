import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { useAsyncCall } from "react-hook-async-call";
import { getAllCareTemplates } from "../../../../services/care-template/getAllCareTemplates";
import { useOnMount } from "../../../../hooks/useOnMount";
import { SelectInput } from "../../../ui/form/inputs/SelectInput";
import { useForm } from "react-hook-form";
import { Alert, Button } from "@mui/material";
import { Patient } from "../../../../model/patient/Patient";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { postAssignCare } from "../../../../services/patient-care/postAssignCare";
import { Loading } from "../../../ui/loading/Loading";
import { useAlerts } from "../../../../hooks/useAlerts";

interface Props {
  open: boolean;
  onClose: (opened: boolean) => void;
  onSubmit: () => void;
  patient?: Patient;
  patientIds?: string[];
}

export const SelectCareDialog = ({ open, onClose, patient, patientIds, onSubmit }: Props) => {
  const alerts = useAlerts();
  const [getAllCare, allCare, fetchLoading] = useAsyncCall(getAllCareTemplates, []);
  const [assignCare, , assignLoading] = useAsyncCall(
    postAssignCare,
    undefined,
    () => {
      alerts.success("Pacientovi byla úspěšně nastavená péče");
      onSubmit();
      onClose(false);
    },
    () => {
      // todo improve error message
      alerts.error("Nastala chyba");
    }
  );

  useOnMount(() => {
    getAllCare();
  });

  const form = useForm<{ selectedCareId: string; careEndDate: string }>();

  const options = allCare.map((care) => ({
    value: care.id,
    label: care.title,
    key: care.id,
  }));

  const handleAssign = () => {
    const ids = patient ? [patient.id] : patientIds ?? [];
    Promise.all(
      ids.map((id) => {
        // todo connect api call for setting the end date of care
        return assignCare(id, form.getValues("selectedCareId"), new Date());
      })
    );
  };

  const buttons = (
    <Button
      onClick={() => {
        handleAssign();
      }}
      color={"success"}
      endIcon={<CheckOutlinedIcon />}
      disabled={!form.watch("selectedCareId") || assignLoading}
    >
      Nastavit
    </Button>
  );

  return (
    <AbstractDialog open={open} onClose={onClose} title={"Výběr šablony péče"} width={"md"} buttons={buttons}>
      {(() => {
        if (fetchLoading) {
          return <Loading />;
        }

        return (
          <>
            <SelectInput name={"selectedCareId"} form={form} options={options} title={"Šablona péče"} required />
            {/* todo */}
            {/*<DateInput name={"careEndDate"} form={form} title={"Datum ukončení péče"} type={"date"} />*/}
            {(() => {
              if (!form.watch("selectedCareId")) {
                return null;
              }

              return (
                <Alert color={"success"}>
                  {patient ? (
                    <>
                      Pacientovi{" "}
                      <strong>
                        {patient.firstName} {patient.lastName}
                      </strong>
                    </>
                  ) : (
                    <>
                      <strong>{patientIds?.length}</strong> pacientům
                    </>
                  )}{" "}
                  bude nastavená péče{" "}
                  <strong>{options.find((option) => option.value === form.watch("selectedCareId"))?.label}</strong>
                </Alert>
              );
            })()}
          </>
        );
      })()}
    </AbstractDialog>
  );
};
