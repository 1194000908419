import { QuestionTemplate, QuestionTemplateBase } from "../../model/templates/QuestionTemplate";
import {
  AnswerDtoIn,
  QuestionBaseTemplateDtoOut,
  QuestionConditionTemplateDtoIn,
  QuestionConditionTemplateDtoOut,
  QuestionTemplateDtoIn,
  QuestionTemplateDtoOut,
} from "../../generated";
import { parseQuestionType } from "../question/parseQuestionType";
import { fromAnswerTemplateToGenerated, parseAnswerTemplate } from "./parseAnswerTemplate";
import { QuestionType } from "../../model/enum/QuestionType";
import { QuestionCondition } from "../../model/templates/QuestionCondition";
import { fromQuestionTypeToGenerated } from "../question/to-generated/fromQuestionTypeToGenerated";

const questionTypesWithOptions = [
  QuestionType.SingleChoiceAnswer,
  QuestionType.MultiChoiceAnswer,
  QuestionType.WellBeing,
];

export const parseQuestionTemplateBase = (data: QuestionBaseTemplateDtoOut): QuestionTemplateBase => ({
  id: data.id,
  type: parseQuestionType(data.type),
  text: data.text,
  answers: data.answers.map(parseAnswerTemplate),
});

export const parseQuestionTemplate = (data: QuestionTemplateDtoOut): QuestionTemplate => ({
  ...parseQuestionTemplateBase(data),
  conditions: data.conditions.map(parseQuestionCondition),
});
const parseQuestionCondition = (data: QuestionConditionTemplateDtoOut): QuestionCondition => ({
  answer: parseAnswerTemplate(data.answerTemplate),
  question: parseQuestionTemplateBase(data.questionTemplate),
});

export const fromQuestionTemplateToGenerated = (data: QuestionTemplate): QuestionTemplateDtoIn => ({
  type: fromQuestionTypeToGenerated(data.type),
  text: data.text,
  answers: getQuestionAnswers(data),
  conditions: fromQuestionConditionsToGenerated(data.conditions),
});

const fromQuestionConditionsToGenerated = (data: QuestionCondition[]): QuestionConditionTemplateDtoIn[] => {
  const answerIds = [];
  for (const condition of data) {
    if (condition.answer) answerIds.push(condition.answer.id);
  }
  return answerIds.map((id) => ({ answerTemplateId: id }));
};

const getQuestionAnswers = (question: QuestionTemplate): AnswerDtoIn[] => {
  if (!questionTypesWithOptions.includes(question.type)) {
    return [];
  }
  return question.answers.map(fromAnswerTemplateToGenerated);
};
